import React, { useState, useRef, useEffect } from 'react';
import styles from './calculator.module.css';
import LazyLoad from 'react-lazyload';
import logo from '../../static/img/logo.png';
import background from '../../static/img/background-bg.png';
import {
  milesDrivenValues,
  energyCostValues,
  rechargePowerValues,
  milesPerKwValues,
  carModelBatteries
} from './data';

function Calculator({ ...props }) {
  const milesDrivenDailyRef = useRef(null);
  const mileskWRef = useRef(null);
  const chargerPowerRef = useRef(null);
  const energyCostRef = useRef(null);
  const batterySizeRef = useRef(null);

  const [titles, setTitles] = useState({});

  const [milesDrivenDaily, setMilesDriven] = useState(120);
  const [mileskW, setMileskW] = useState(3);
  const [chargerPower, setChargerPower] = useState(6000);
  const [energyCost, setEnergyCost] = useState(0.2);
  const [batterySize, setBatterySize] = useState(65);

  const [manualInput, setManualInput] = useState(true);

  useEffect(() => {
    milesDrivenDailyRef.current.value = 120;
    mileskWRef.current.value = 3;
    chargerPowerRef.current.value = 6000;
    energyCostRef.current.value = 0.2;
    batterySizeRef.current.value = 1;
    fetch('https://prox-ev.content-proxy-ev.workers.dev/')
      .then(response => response.json())
      .then(response => setTitles(response));
  }, []);

  const batteryUsed =
    mileskW !== 0 && batterySize !== 0
      ? milesDrivenDaily / mileskW / batterySize
      : 0;
  const milesRange = (mileskW * chargerPower) / 1000;
  const amps = chargerPower / 240;
  const costPerHour = (energyCost * chargerPower) / 1000;
  const circuitPerCharge = Math.ceil(amps * 1.25);
  const chargeCost =
    mileskW !== 0 ? energyCost * (milesDrivenDaily / mileskW) : 0;
  const hoursToCharge = milesDrivenDaily / mileskW / (chargerPower / 1000);
  const costPerMile =
    milesDrivenDaily !== 0 ? chargeCost / milesDrivenDaily : 0;
  const systemNeeded = mileskW !== 0 ? milesDrivenDaily / mileskW / 5.25 : 0;
  const solarCost = systemNeeded * 4 * 1000;

  function calculate() {
    setMilesDriven(milesDrivenDailyRef.current.value);
    setMileskW(mileskWRef.current.value);
    setChargerPower(chargerPowerRef.current.value);
    setEnergyCost(energyCostRef.current.value);
    setBatterySize(batterySizeRef.current.value);
  }

  return (
    <div className={styles.wrapper}>
      <LazyLoad>
        <img
          className={styles.backgroundImg}
          src={background}
          alt="background"
        />
      </LazyLoad>
      <header>
        <LazyLoad>
          <img width="200px" alt="company logo" src={logo} />
        </LazyLoad>
      </header>
      <div className={styles.container}>
        <h1>Electric Car Charging Calculator</h1>
        <p>
          We calculate charging power and time requirements based on daily drive
          miles. Solar system size is based on annual averages.
        </p>
        <div className={`${styles.row} ${styles.spaced}`}>
          <div className={`${styles.itemWrapper}`}>
            <label className={styles.label}>
              {titles.miles_driven_per_day || ''}
            </label>
            <select className={`${styles.short}`} ref={milesDrivenDailyRef}>
              {milesDrivenValues.map(qty => (
                <option key={qty} value={qty}>
                  {qty}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>{'Car Model'}</label>
            <select
              style={{ width: '150px' }}
              onChange={event => {
                if (event.target.value === 'manual') {
                  setManualInput(true);
                  batterySizeRef.current.focus();
                } else {
                  setManualInput(false);
                  setBatterySize(event.target.value);
                }
              }}
            >
              {Object.entries(carModelBatteries).map(([key, value]) => (
                <option key={key} value={value}>
                  {key}
                </option>
              ))}
            </select>
          </div>
          <div className={`${styles.itemWrapper} ${styles.marginRight}`}>
            <label className={styles.label}>{titles.battery_size || ''}</label>
            <input
              type="number"
              className={`${styles.short} `}
              ref={batterySizeRef}
              disabled={!manualInput}
              value={batterySize}
              onChange={event => {
                setBatterySize(event.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          {/* first row */}
          <div className={styles.itemWrapper}>
            <label className={styles.label}>{titles.miles_per_kw || ''}</label>
            <select ref={mileskWRef}>
              {milesPerKwValues.map(qty => (
                <option key={qty} value={qty}>
                  {qty}
                </option>
              ))}
              }
            </select>
          </div>
          <div className={` ${styles.itemWrapper}`}>
            <label className={styles.label}>
              {titles.percent_of_battery_charge_used || ''}
            </label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              {(batteryUsed * 100).toFixed(0)}%
            </span>
          </div>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>
              {titles.miles_of_range_per_charge_hour || ''}
            </label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              {milesRange.toFixed(0)}
            </span>
          </div>
          {/* second row */}
          <div className={styles.itemWrapper}>
            <label className={styles.label}>{titles.charger_power || ''}</label>
            <select ref={chargerPowerRef}>
              {rechargePowerValues.map(qty => (
                <option key={qty} value={qty}>
                  {qty}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>{titles.amps_at_240_v || ''}</label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              {amps.toFixed(1)}
            </span>
          </div>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>
              {titles.charge_cost_per_hour || ''}
            </label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              ${costPerHour.toFixed(2)}
            </span>
          </div>
          {/* third row */}
          <div className={styles.itemWrapper}>
            <label className={styles.label}>{titles.energy_cost || ''}</label>
            <select ref={energyCostRef}>
              {energyCostValues.map(qty => (
                <option key={qty} value={qty}>
                  {qty}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>
              {titles.branch_circuit_for_charger || ''}
            </label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              {circuitPerCharge}
            </span>
          </div>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>
              {titles.charger_cost_per_complete_recharge || ''}
            </label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              ${chargeCost.toFixed(0)}
            </span>
          </div>
        </div>

        <div className={`${styles.row} ${styles.center}`}>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>
              {titles.hours_per_recharge || ''}
            </label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              {hoursToCharge.toFixed(1)}
            </span>
          </div>
        </div>

        <div className={`${styles.row} ${styles.center}`}>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>{titles.cost_per_mile || ''}</label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              ${costPerMile.toFixed(2)}
            </span>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.itemWrapper}>
            <button onClick={calculate}>Calculate new results</button>
          </div>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>
              <strong>{titles.solar_system_needed_to_charge || ''}</strong>
            </label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              {systemNeeded.toFixed(2)}
            </span>
          </div>
          <div className={styles.itemWrapper}>
            <label className={styles.label}>
              <strong>
                {titles.approx_cost_of_rooftop_solar_system || ''}
              </strong>
            </label>
            <span className={`${styles.calculatedValue} ${styles.outputBox}`}>
              $
              {solarCost
                .toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>
          </div>
        </div>
        <span>ENTER YOUR DETAILS TO SEE IF SOLAR WORKS FOR YOU</span>
      </div>
    </div>
  );
}
export default Calculator;
