import React from 'react';
import styles from './footer.module.css';

function Footer({ titles, ...props }) {
  return (
    <div className={styles.container}>
      <div className={styles.innerWrapper}>
        <h3>
          For more info visit{' '}
          <a href="https://www.sunnycalsolar.com">www.sunnycalsolar.com</a> or
          call{' '}
          <phone>
            <a href="tel:+12094646100">209-464-6100</a>
          </phone>
        </h3>
        <p>
          This site is provided as an educational tool to enable users to manage
          electrical power requirements, and to be able to see benefits of solar
          power when applied to the users data entries.
        </p>
        <p>
          The information provided here will be used only by the provider of
          this website and our associates; it will not be sold or distributed to
          any third party. The results screen will be emailed to the user's
          email addresses; by providing the email address, the user gives
          permission for the website to transmit email to users.
        </p>

        <p>{titles.copyright} </p>
      </div>
    </div>
  );
}
export default Footer;
