import React from 'react';
import styles from './description.module.css';
import LazyLoad from 'react-lazyload';

import calculator from '../../static/img/calculator.png';
import piggyBank from '../../static/img/piggy-bank.png';
import solarPannel from '../../static/img/solar-pannel.png';

function Description({ ...props }) {
  return (
    <div className={styles.container}>
      <div className={styles.dashedBkg} />
      <h3>3 Easy Steps To Going Solar</h3>
      <LazyLoad>
        <div className={styles.row}>
          <article>
            <img alt="calculator" src={calculator} />
            <p>
              Enter your driving miles and utility cost in order to see charging
              requirements
            </p>
          </article>
          <article>
            <img alt="solar pannel" src={solarPannel} />
            <p>Install solar system at your home</p>
          </article>
          <article>
            <img alt="piggy bank" src={piggyBank} />
            <p>Start saving on your electrical bills</p>
          </article>
        </div>
      </LazyLoad>
    </div>
  );
}
export default Description;
