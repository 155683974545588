import React,{useState,useEffect} from 'react';
import Calculator from './components/calculator';
import Description from './components/description';
import Footer from './components/footer';

function App() {
	const [titles, setTitles] = useState({});
	useEffect(() => {
		fetch('https://prox-ev.content-proxy-ev.workers.dev/')
			.then(response => response.json())
			.then(response => setTitles(response));
	}, []);
	return (
		<>
			<Calculator />
			<Description />
			<Footer titles={titles} />
		</>
	);
}

export default App;
