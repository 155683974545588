export const milesDrivenValues = [
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
  130,
  140,
  150,
  160,
  170,
  180,
  190,
  200
];
export const batterySizeValues = [
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  105
];
export const energyCostValues = [
  0.07,
  0.08,
  0.09,
  0.1,
  0.11,
  0.12,
  0.13,
  0.14,
  0.15,
  0.16,
  0.17,
  0.18,
  0.19,
  0.2,
  0.21,
  0.22,
  0.23,
  0.24,
  0.25,
  0.26,
  0.27,
  0.28,
  0.29,
  0.3,
  0.31,
  0.32,
  0.33,
  0.34,
  0.35,
  0.36,
  0.37,
  0.38,
  0.39,
  0.4
];
export const rechargePowerValues = [
  1000,
  1200,
  1400,
  1600,
  1800,
  2000,
  2200,
  2400,
  2600,
  2800,
  3000,
  3200,
  3400,
  3600,
  3800,
  4000,
  4200,
  4400,
  4600,
  4800,
  5000,
  5200,
  5400,
  5600,
  5800,
  6000,
  6200,
  6400,
  6600,
  6800,
  7000,
  7200,
  7400,
  7600
];
export const milesPerKwValues = [
  2,
  2.5,
  3,
  3.5,
  4,
  4.5,
  5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10
];

export const carModelBatteries = {
  'Manual Entry': 'manual',
  'BMW i3 42kWh': 42,
  'Chevy Bolt   60 kWh': 60,
  'Chevy Volt PHEV  16kWh': 16,
  'Chrysler Pacifica Hybrid': 16.2,
  'Ford Focus 23kWh': 23,
  'Hyundai Kona Electric (EV) 39kWh': 39,
  'Hyundai Kona Electric (EV) 64kWh': 64,
  'Kia Soul EV  27kWh': 27,
  'Mitsubishi iMiEV  16kWh': 16,
  'Nissan Leaf 30kWh': 30,
  'Smart Fortwo ED  16.5kWh': 16,
  'Tesla 3  75kWh': 75,
  'Tesla S  70kWh': 70,
  'Tesla S  90kWh': 90,
  'Tesla X  100kWh': 100,
  'Tesla X  60kWh': 60,
  'Tesla X  70kWh': 70,
  'Tesla X  75kWh': 75,
  'Tesla X  90kWh': 90,
  'Toyota Prius PHEV 4.4kWh': 4.4,
  'Volkswagon eGolf  35.8': 35.8
};
